<template>
      <el-dialog
        :visible.sync="showDialog"
        :title="title"
        :modal-append-to-body="false"
        style="text-align: left"
        @close="closeDialog"
        width="1000px"
        :close-on-click-modal="false"
      >
      <div class="user-panel" v-loading="loading">
        <el-form
            ref="form"
            :model="formModel"
            :rules="ruleValidate"
            :label-width="'150px'"
            inline
        >
            <el-form-item label="物业公司" prop="companyId">
                <!-- <el-select v-model="formModel.companyId" filterable>
                    <el-option
                        v-for="result in companyList"
                        :key="result.id"
                        :label="result.name"
                        :value="result.id"
                    ></el-option>
                </el-select> -->
                <el-select-tree
                    :props="props"
                    :options="treeData"
                    v-model="formModel.companyId"
                    height="200"
                    style="width: 300px"
                ></el-select-tree>
            </el-form-item>
            <el-form-item label="账户名称" prop="name">
                <el-input
                    v-model="formModel.name"
                    placeholder="请输入账户名称"
                    style="width: 300px"
                ></el-input>
            </el-form-item>
            <el-form-item label="结算周期" prop="cycle">
                <el-input
                    v-model="formModel.cycle"
                    placeholder="结算周期"
                    style="width: 300px"
                >
                     <template slot="append">天以内</template>
                </el-input>
            </el-form-item>
            <el-form-item label="微信商户号" prop="mchId">
                <el-input
                    v-model="formModel.mchId"
                    placeholder="请输入微信mchId"
                    style="width: 300px"
                ></el-input>
            </el-form-item>
            <el-form-item label="微信子商户号" prop="subMchId">
                <el-input
                    v-model="formModel.subMchId"
                    placeholder="请输入微信subMchId"
                    style="width: 300px"
                ></el-input>
            </el-form-item>
            <el-form-item label="支付宝令牌" prop="appAuthToken">
                <el-input
                    v-model="formModel.appAuthToken"
                    placeholder="请输入支付宝appAuthToken"
                    style="width: 300px"
                ></el-input>
            </el-form-item>
            <el-form-item label="开户名称" prop="bankName">
                <el-input
                    v-model="formModel.bankName"
                    placeholder="请输入开户名称"
                    style="width: 300px"
                ></el-input>
            </el-form-item>
                        <el-form-item label="开户银行" prop="bank">
                <el-input
                    v-model="formModel.bank"
                    placeholder="请输入开户银行"
                    style="width: 300px"
                ></el-input>
            </el-form-item>
            <el-form-item label="账号" prop="account">
                <el-input
                    v-model="formModel.account"
                    placeholder="请输入账号"
                    style="width: 300px"
                ></el-input>
            </el-form-item>
            <el-form-item label="财务人员手机账户" prop="financePersonId">
                <el-select v-model="formModel.financePersonId" filterable style="width: 300px">
                    <el-option
                        v-for="result in personList"
                        :key="result.id"
                        :label="result.name + '(' + result.phone + ')'"
                        :value="result.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="财务人员后台账户" prop="financeUserId">
                <el-select v-model="formModel.financeUserId" filterable style="width: 300px">
                    <el-option
                        v-for="result in userList"
                        :key="result.id"
                        :label="result.realName + '(' + result.userName + ')'"
                        :value="result.id"
                    ></el-option>
                </el-select>
            </el-form-item>

        </el-form>
      </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="handleSubmit" :loading="submitting"
            >确 定</el-button
        >
        </span>
      </el-dialog>
      
</template>
<script>
import Constant from "@/constant";
import companyPaymentApi from "@/api/base/companyPayment";
import companyInfoApi from "@/api/base/companyInfo";
import pageUtil from "@/utils/page";
import SelectTree from "@/components/SelectTree";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import personInfoApi from "@/api/base/personInfo";
export default {
    props: ["businessKey", "title"],
    data() {
        return {
            ruleValidate: {
                companyId: [{ required: true, message: "物业公司不能为空", trigger: "change" }],
                name: [{ required: true, message: "账户名称不能为空", trigger: "blur" }],
                cycle: [{ required: true, message: "结算周期不能为空", trigger: "blur" }],

            },
            formModel:{
                companyId:"", 
                name:"",
                mchId:"",
                subMchId:"",
                appAuthToken:"",
                financePersonId:"",
                financeUserId:"",
            },
            treeData: [],
            personList:[],
            userList:[],
            companyList:[],
            showDialog: true,
            loading: false,
            submitting: false,
            props: {
                // 配置项（必选）
                value: "id",
                label: "name",
                children: "children"
            },
        }
    },
    created() {
        this.loadTree();

        companyPaymentApi.findPersonByCompanyId().then(response => {
            var jsonData = response.data;
            this.personList = jsonData.data;
        });

        companyPaymentApi.findUserByCompanyId().then(response => {
            var jsonData = response.data;
            this.userList = jsonData.data;
        });
    },
    methods: {
        closeDialog() {
            this.$emit("close", false);
        },
        handleSubmit() {
            var self = this;

            this.$refs["form"].validate((valid) => {
                if (valid) {
                (function () {
                    var id = self.formModel.id;

                    if (id == null || id.length == 0) {
                        return companyPaymentApi.add(self.formModel);
                    } else {
                        return companyPaymentApi.update(self.formModel);
                    }
                })().then(function (response) {
                    var jsonData = response.data;

                    if (jsonData.result) {
                    self.$message({
                        message: "保存成功!",
                        type: "success",
                    });

                    self.$emit("close", true);
                    } else {
                    self.$message({
                        message: jsonData.message + "",
                        type: "warning",
                    });

                    self.$emit("close", false);
                    }
                });
                }
            });
        },
        loadTree() {
            companyInfoApi.treeList().then(resp => {
                var jsonData = resp.data;

                if (jsonData.result) {
                this.treeData = jsonData.data;
                } else {
                this.$message.error(jsonData.message + "");
                }
            });
        },
    },
    mounted: function () {
        var self = this;

        companyInfoApi.treeList().then((resp) => {
            var jsonData = resp.data;
            this.companyList = jsonData.data;
        });

        (function () {
        if (self.businessKey.length == 0) {
            return companyPaymentApi.create();
        } else {
            return companyPaymentApi.edit(self.businessKey);
        }
        })()
        .then((response) => {
            var jsonData = response.data;
            self.loading = false;

            if (jsonData.result) {
            self.formModel = jsonData.data;
            } else {
            self.$message.error(jsonData.message + "");
            }
        })
        .catch((error) => {
            self.$message.error(error + "");
        });
    },
    components: {
        "el-select-tree": SelectTree
    }
}
</script>
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>