<template>
    <div>
        <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>
            <a href="#">系统管理</a>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
            <a href="#">园区管理</a>
        </el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
        <el-form
            ref="queryForm"
            :model="queryModel"
            inline
            class="demo-form-inline"
            >
            <el-form-item label="物业公司" prop="companyId">
                <!-- <el-select size="mini" v-model="queryModel.companyId" filterable>
                    <el-option
                        v-for="result in companyList"
                        :key="result.id"
                        :label="result.name"
                        :value="result.id"
                    ></el-option>
                </el-select> -->
                <el-select-tree
                    :props="props"
                    :options="treeData"
                    v-model="queryModel.companyId"
                    height="200"
                ></el-select-tree>
            </el-form-item>
            <el-form-item label="账户名称" prop="name">
                <el-input type="text" size="mini" v-model="queryModel.name"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="changePage(1)"
                :loading="loading"
                >查询</el-button
                >&nbsp;
                <el-button
                type="info"
                size="mini"
                style="margin-left: 8px"
                @click="handleReset('queryForm')"
                >重置</el-button
                >&nbsp;
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-row class="button-group">
        <el-button
            type="primary"
            size="small"
            plain
            icon="el-icon-circle-plus"
            @click="handleAdd(null)"
        >新增</el-button>
        </el-row>
        <el-table
            :data="tableData"
            style="min-height:400px;"
            row-key="id"
            stripe
            border
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            @selection-change="handleSelectionChange"
            >
            <el-table-column prop="companyName"  label="物业公司" ></el-table-column>
            <el-table-column prop="name"  label="账户名称" ></el-table-column>
            <el-table-column prop="cycle"  label="结算周期"  >
                <template slot-scope="{row}">
                    {{row.cycle}}天以内
                </template>
            </el-table-column>
            <el-table-column prop="mchId"  label="微信mchId" ></el-table-column>
            <el-table-column prop="subMchId"  label="微信subMchId" ></el-table-column>
            <el-table-column prop="appAuthToken"  label="支付宝appAuthToken"  ></el-table-column>
            <el-table-column prop="bankName"  label="开户名称"  ></el-table-column>
            <el-table-column prop="bank"  label="开户银行"  ></el-table-column>
            <el-table-column prop="account"  label="账号"  ></el-table-column>
            <el-table-column prop="createTime"  label="创建时间"  ></el-table-column>
            <el-table-column prop="isDefault"  label="默认账户">
                <template slot-scope="{row}">
                    <el-switch
                        v-model="row.isDefault"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        @change="handlChange(row)">
                    </el-switch>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="isOpenWechat"  label="支持微信支付"  >
                <template slot-scope="{row}">
                    <el-switch
                        v-model="row.isOpenWechat"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        @change="handlChange(row)">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="isOpenAlipay"  label="支持支付宝支付"  >
                <template slot-scope="{row}">
                    <el-switch
                        v-model="row.isOpenAlipay"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        @change="handlChange(row)">
                    </el-switch>
                </template>
            </el-table-column> -->
            <el-table-column label="操作" fixed="right" width="180px">
                <template slot-scope="{row}">
                <el-link size="mini" type="primary" @click="handleEdit(row)">编辑</el-link>-
                <el-link size="mini" type="warning" @click="handleManagePayService(row)">收费项目</el-link>-
                <el-link size="mini" type="danger" @click="handleDelete(row)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            :current-page.sync="pageIndex"
            :total="totalElements"
            :page-sizes="pageSizeList"
            @current-change="changePage"
            @size-change="pageSizeChange"
            layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
        <companyPayment-detail
            v-if="showModal"
            :businessKey="businessKey"
            :title="modalTitle"
            @close="onDetailModalClose"
        ></companyPayment-detail>
    </div>
</template>
<script>
import Constant from "@/constant";
import companyPaymentApi from "@/api/base/companyPayment";
import companyInfoApi from "@/api/base/companyInfo";
import companyPaymentDetail from "./companyPayment-detail";
import pageUtil from "@/utils/page";
import SelectTree from "@/components/SelectTree";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
export default {
     name: "baseCompanyPayment",
     data() {
         return{
            queryModel:{
                companyId:"",
                name:"",
            },
            treeData: [],
            companyList:[],
            loading: false,
            tableData: [],
            pageIndex: 1,
            pageSize: 10,
            totalPages: 0,
            totalElements: 0,
            pageSizeList: [10, 20, 30],
            multipleSelection: [],
            showModal: false,
            props: {
                // 配置项（必选）
                value: "id",
                label: "name",
                children: "children"
            },
         }
    },
    created() {

        this.loadTree();
    },
    methods: {
        changePage(pageIndex) {
            var self = this;

            self.loading = true;

            var formData = new FormData();

            formData.append("pageIndex", self.pageIndex);
            formData.append("pageSize", self.pageSize);

            formData.append("companyId", self.queryModel.companyId);
            formData.append("name", self.queryModel.name);

            companyPaymentApi.pageList(formData).then(function (response) {
                self.loading = false;

                var jsonData = response.data.data;

                self.tableData = jsonData.data;
                self.totalPages = jsonData.totalPages;
                self.totalElements = jsonData.recordsTotal;
            })
            .catch((error) => {
            self.loading = false;
            // self.$message.error(error + "");
            });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
        },
        handleAdd() {
            this.modalTitle = "新增";
            this.businessKey = "";
            this.showModal = true;
        },
        handleEdit(record) {
            this.modalTitle = "编辑";
            this.businessKey = record.id;
            this.showModal = true;
        },
        handleManagePayService(record) {

            var path = "/base/PayService/list";
            

            this.$store.dispatch('tagsView/delView', {
                name: "basePayServiceList",
                path: path
            }).then(({ visitedViews }) => {
                this.$router.push({ 
                path: path,
                query:{
                    companyId: record.companyId
                }
                });
            });
        },
        onDetailModalClose(refreshed) {
            //保存成功后回调
            this.showModal = false;

            if (refreshed) {
                this.changePage(this.pageIndex);
            }
        },
        handleDelete(record) {
            var self = this;

            self.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                })
                .then(() => {
                companyPaymentApi.remove(record.id).then(function (response) {
                    var jsonData = response.data;

                    if (jsonData.result) {
                    // var index = self.tableData.indexOf(record);
                    // self.tableData.splice(index, 1);
                    self.changePage(self.pageIndex);

                    self.$message({
                        type: "success",
                        message: "删除成功!",
                    });
                    }
                });
            });
        },
        loadTree() {
            companyInfoApi.treeList().then(resp => {
                var jsonData = resp.data;

                if (jsonData.result) {
                this.treeData = jsonData.data;
                } else {
                this.$message.error(jsonData.message + "");
                }
            });
        },
        handlChange(record){

            var self = this;
            var formData = new FormData();
            self.loading = true;

            formData.append("id", record.id);
            formData.append("isDefault", record.isDefault);

            companyPaymentApi.setIsDefault(formData).then(function (response) {
                var jsonData = response.data;
                self.loading = false;
                if (jsonData.result) {
                    self.changePage(self.pageIndex);
                }
                else{
                    self.$message.error(jsonData.message + "");
                }
            });
        }
        
    },
    components: {
        "companyPayment-detail": companyPaymentDetail,
        "el-select-tree": SelectTree
    },
    mounted() {
        this.changePage(1);

        companyInfoApi.treeList().then((resp) => {
            var jsonData = resp.data;
            this.companyList = jsonData.data;
        });
    },
}
</script>
<style scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>